<template>
  <a-layout-header class="header">
    <a-row
      :gutter="25"
      class="custom-height-100p custom-flex custom-items-center"
    >
      <a-col
        :span="12"
        class="custom-height-100p custom-flex custom-items-center"
      >
        <a-space @click="handleToHome" style="cursor: pointer;">
          <div class="logo custom-flex custom-items-center custom-justify-center">
            <img :src="logoUrl" alt="logo">
          </div>
          <h1 class="custom-margin-0 custom-ellipsis name">{{ appName }}</h1>
        </a-space>
      </a-col>

      <a-col
        :span="12"
        class="custom-flex custom-justify-end"
      >
        <div class="links">
          <div
            class="notification-box custom-cursor-pointer"
            v-if="isShowMenu('menu:tenant_orders')"
            @click="showNoticeModal"
          >
            <div class="notification-content">
              <a-badge
                :count="unreadNotificationCount"
                :overflow-count="99"
                :number-style="{
                  backgroundColor: this.$primaryColor,
                  fontSize: '1px'
                }"
              />

              <a-tooltip>
                <template slot="title">
                  通知消息
                </template>

                <a-icon type="bell" />
              </a-tooltip>
            </div>
          </div>
        </div>

        <div class="user-info-wrapper">
          <a-dropdown placement="bottomRight">
            <a-space class="custom-cursor-pointer">
              <a-avatar icon="user" />
              <div class="user-full-name">
                {{ this.$store.getters.userEmpName }}
              </div>
            </a-space>
            <a-menu slot="overlay">
              <a-menu-item
                key="info"
              >
                <router-link :to="{ name: 'user_info', params: { id: this.$store.getters.userId }}">
                  <a-space>
                    <a-icon type="user" />个人资料
                  </a-space>
                </router-link>
              </a-menu-item>
              <a-menu-item
                key="logout"
                @click="handleLogout"
              >
                <a-space>
                  <a-icon type="logout" />
                  退出系统
                </a-space>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-col>
    </a-row>

    <notice-list
      v-if="isShowNoticeModal"
      :visible.sync="isShowNoticeModal"
    />

    <show-notification
      v-if="isShowNotificationModal"
      :visible.sync="isShowNotificationModal"
      :id="showingNotificationId"
      @alreadyRead="alreadyReadAutoAlertNotification"
    />

    <component
      :key="globalSearch.componentKey"
      :is="globalSearch.currentComponent"
      :id="globalSearch.recordId"
      :visible.sync="globalSearch.visible"
    />
  </a-layout-header>
</template>

<script>
import { getOriginalUserUid, removeOriginalUserUid } from '@/utils/token'
import { switchBack } from '@/api/switch_user'
import { setPageTitle } from '@/utils/dom_util'
import { findAlertNotification } from '@/api/notification'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'HeaderNavbar',
  components: {
    NoticeList: () => import('@/views/notices/index'),
    ShowNotification: () => import('@/views/notifications/Show'),
    PersonalInfo: () => import('@/views/users/personal_info/index')
  },
  data() {
    return {
      showingNotificationId: 0, // 显示的通知消息id
      isShowNotificationModal: false, // 是否显示通知消息弹窗
      isShowNoticeModal: false,
      globalSearch: {
        key: '', // 全局搜索的值
        loading: false, // 加载中
        recordId: 0, // 记录id
        visible: false, // 是否显示
        currentComponent: '', // 当前用来显示 record 的组件
        componentKey: '' // 组件key，用来强制刷新组件
      },
      notificationTimer: null
      // tts: new SpeakTts()
    }
  },
  watch: {
    // 设置浏览器 tab 中新消息条数
    unreadNotificationCount(val, oldVal) {
      setPageTitle(this.$route)
    }
  },
  computed: {
    isSwitchedUser() {
      return getOriginalUserUid()
    },

    // 未读通知个数
    unreadNotificationCount() {
      return this.$store.getters.unreadNotificationCount
    },

    homePageUrl() {
      return window.customConfig.homePageUrl
    },

    logoUrl() {
      return window.customConfig.logoUrl
    },

    appName() {
      return window.customConfig.name
    }
  },
  created() {
    this.$pullNotifications()
    this.$socket.subscribe('notice_messages', this.speak)
  },
  destroyed() {
    this.$socket.unsubscribe('notice_messages', this.$pullNotifications)
  },
  methods: {
    speak() {
      const audio = new Audio()// 在VUE中使用audio标签
      audio.src = window.customConfig.order_prompt_sound // 设置audio的src为上面生成的url
      audio.play()// 进行播放
      this.$pullNotifications()
    },

    // speak(text) {
    //   text = '有新的订单，需要尽快处理'
    //   this.tts.setLanguage('zh-CN')
    //   this.tts.setVoice('Fred')
    //   // this.tts.setVoice('Microsoft Kangkang - Chinese (Simplified, PRC)')
    //   this.tts.speak({
    //     text: text,
    //     queue: false,
    //     listeners: {
    //       onend: () => {
    //         console.log('finished speaking')
    //       }
    //     }
    //   })
    // },

    pullNotifications(res) {
      if (res && res.includes(this.$store.getters.userId)) {
        // this.speak('有新的订单，需要尽快处理')
      }
      setTimeout(() => {
        this.$pullNotifications()
      }, parseInt(Math.random() * 30) * 1000)
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    // 轮询检测新的通知消息
    loopCheckNotifications() {
      if (!this.isShowMenu('menu:system_orders') && !this.isShowMenu('menu:tenant_orders')) {
        return
      }
      this.$pullNotifications()
      this.notificationTimer = setInterval(this.$pullNotifications, 10000)
    },

    showNoticeModal() {
      this.isShowNoticeModal = true
    },

    // 自动弹出需要展示的通知消息
    autoAlertNotification() {
      if (!this.$store.getters.isNeedCheckAutoAlertNotification) {
        return
      }

      findAlertNotification().then((res) => {
        if (res.code === 0) {
          this.$store.dispatch('SetIsNeedCheckAutoAlertNotification', false)
          this.showingNotificationId = res.data.id
          this.isShowNotificationModal = res.data.is_alert
        }
      })
    },

    // 自动弹出的通知消息已读
    alreadyReadAutoAlertNotification(id) {
      this.$store.dispatch('SetAutoAlertNotificationId', id)
    },

    // 切回原账号登录
    switchBack() {
      const originalUserUid = getOriginalUserUid()
      if (!originalUserUid) {
        window.location.reload()
        return
      }

      switchBack({ original_user_uid: getOriginalUserUid() }).then((res) => {
        if (res.code === 0) {
          location.href = res.data.redirect_url
        } else {
          removeOriginalUserUid()
        }
      })
    },

    handleToHome() {
      this.$router.push('/')
    },

    handleLogout() {
      return this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  padding: 0 20px;
  background-color: @black;
}

.logo {
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    height: 50px;
  }
}

.name {
  color: @white;
}

.user-full-name {
  max-width: 200px;
  font-size: 16px;
  overflow:hidden;//隐藏文字
  text-overflow:ellipsis;//显示 ...
  white-space:nowrap;//不换行
  color: @white;
}

.links {
  margin-right: 10px;

  // 小圆点样式
  /deep/ .ant-badge-not-a-wrapper {
    position: absolute;
    right: 1px;
    top: 10px;
  }

  .notification-box {
    display: flex;
    margin-bottom: 0px;

    .notification-content {
      position: relative;
      display: inline-flex;
      width: 50px;
      height: 64px;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      align-content: center;

      a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 5px 0;
      }

      i {
        color: #b1b0b0;
        font-size: 25px;
        cursor: pointer;
      }

      &:hover {
        background-color: @primary-color;

        i {
          color: #fff;
        }
      }
    }
  }
}
</style>
