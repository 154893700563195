import Vue from 'vue'
import { getToken } from '@/utils/token'
import { generateUUID } from 'ant-design-vue/lib/vc-select/util'
import { INVALID_TOKEN_CODE, USER_LOCKED_CODE } from '@/utils/api_code'
import store from '@/store'
import router from '@/router'

const emitter = new Vue({
  data() {
    return {
      socket: undefined,
      isReconnect: true, // 是否需要重连
      connecting: false, // 是否正在连接(connect方法)
      reconnectConfig: {
        duration: 3 * 1000, // 重连间隔时间
        timer: undefined // 重连 timer
      },
      checkingState: false, // 是否正在检查状态
      heartbeatConfig: {
        duration: 5 * 1000, // 心跳间隔时间
        timer: undefined // 心跳 timer
      }
    }
  },
  created() {
    this.connect()
  },
  methods: {
    // 连接
    connect() {
      if (this.connecting) {
        return
      }

      if (this.socket && (this.socket.readyState === this.socket.OPEN || this.socket.readyState === this.socket.CONNECTING)) {
        return
      }

      if (!('WebSocket' in window)) {
        console.log('浏览器不支持WebSocket')
        return
      }

      const token = getToken()
      if (!token) {
        return
      }

      const wsUrl = `${window.customConfig.websocketBaseUrl}?token=${token}`
      this.socket = new WebSocket(wsUrl)
      this.socket.onopen = this.onSocketOpen
      this.socket.onmessage = this.onSocketMessage
      this.socket.onclose = this.onSocketClose

      this.connecting = false
    },

    // 关闭连接
    close() {
      clearInterval(this.heartbeatConfig.timer)
      clearTimeout(this.reconnectConfig.timer)
      this.isReconnect = false
      if (this.socket) this.socket.close()
    },

    // 发送数据, 如: send('heartbeat', { token: '' })
    send(cmd, data, options = {}) {
      if (this.socket.readyState !== this.socket.OPEN) {
        return false
      }

      this.socket.send(JSON.stringify({
        cmd: cmd,
        request_id: options.requestId,
        data: data
      }))
      return true
    },

    // 发送心跳数据
    heartbeat() {
      this.send('heartbeat', {
        token: getToken()
      })
    },

    // 订阅
    subscribe(cmd, method) {
      this.$on(cmd, method)
    },

    // 取消订阅
    unsubscribe(cmd, method) {
      this.$off(cmd, method)
    },

    // 一次性订阅(收到消息即取消订阅)
    onceSubscribe(cmd, method) {
      this.$once(cmd, method)
    },

    // 模拟请求、应答(根据 requestId 发送消息, 接收同样 requestId 的应答消息, 作为模拟请求的响应数据)
    request(cmd, data) {
      return new Promise((resolve, reject) => {
        const requestId = generateUUID()
        // 订阅
        this.onceSubscribe(`${cmd}-${requestId}`, resolve)

        // 发送消息
        if (!this.send(cmd, data, { requestId: requestId })) {
          reject(new Error('request failed'))
        }
      })
    },

    onSocketOpen() {
      if (this.heartbeatConfig.timer) {
        clearInterval(this.heartbeatConfig.timer)
      }
      this.heartbeatConfig.timer = setInterval(this.heartbeat, this.heartbeatConfig.duration)
    },

    onSocketMessage(message) {
      const messageData = JSON.parse(message.data)
      if (typeof messageData === 'object' && messageData.cmd) {
        const res = messageData.data
        if (res) {
          if (res.code === INVALID_TOKEN_CODE || res.code === USER_LOCKED_CODE) {
            this.close()
            store.dispatch('ClearLoginInfo').then(() => {
              router.push({ name: 'login' }).catch(() => {})
            })
          }
        }

        // 通知订阅者
        if (messageData.request_id) {
          this.$emit(`${messageData.cmd}-${messageData.request_id}`, res)
        } else {
          this.$emit(messageData.cmd, res)
        }
      } else {
        console.log('Invalid socket message')
      }
    },

    onSocketClose() {
      clearInterval(this.heartbeatConfig.timer)

      if (this.isReconnect) {
        this.reconnectConfig.timer = setTimeout(() => {
          this.connect()
        }, this.reconnectConfig.duration)
      }
    }
  }
})

export default emitter
