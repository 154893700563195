import BasicLayout from '@/layouts/BasicLayout'
import Empty from '@/layouts/Empty'
import CustomStyleLayout from '@/layouts/CustomStyleLayout'

// meta 部分说明
// 如 meta: { title: '用户管理', permission: 'users', menu: 'users' }
// permission 用于权限控制，api 返回的权限列表中有 users 才可以访问
// menu       用于菜单选中高亮，如果不是菜单则可以不传值，值需要和 a-menu-item key="users" 的key值相同
// title      用于浏览器 tab 显示以及面包屑显示
//            - 以 ':' 开头的 title 为动态的值占位符, 用作业务逻辑中的值替换
//              可使用 SetDynamicBreadCrumbsPlaceholder 方法替换占位符的值, 如：
//              this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':agent_name': '客户1' })
//            - 还提供了 ConcatDynamicBreadCrumbs 方法追加面包屑, 常用于面包屑生成规则不统一，自行生成面包屑

// 异步路由(需要走权限控制)
export const asyncRouter = [
  {
    path: '/user',
    name: 'user',
    component: BasicLayout,
    children: [
      {
        path: ':id',
        name: 'user_info',
        component: () => import('@/views/users/personal_info/index'),
        meta: { title: '个人资料' }
      }
    ]
  },

  {
    path: '/tenant_orders',
    component: BasicLayout,
    children: [
      {
        path: '/tenant_orders',
        name: 'tenant_orders',
        component: () => import('@/views/orders/tenant/index.vue'),
        meta: { title: '订单管理', menu: 'tenant_orders', permission: 'menu:tenant_orders' }
      }
    ]
  },
  {
    path: '/hall_inspections',
    component: BasicLayout,
    children: [
      {
        path: '/hall_inspections',
        name: 'hall_inspections',
        component: () => import('@/views/hall_inspections/index.vue'),
        meta: { title: '巡检记录', menu: 'hall_inspections', permission: 'menu:hall_inspections' }
      }
    ]
  },
  {
    path: '/tenant_orders',
    component: CustomStyleLayout,
    meta: { menu: 'tenant_orders' },
    children: [
      {
        path: '/tenant_orders',
        component: Empty,
        meta: { menu: 'tenant_orders' },
        children: [
          {
            path: '/tenant_orders',
            component: Empty,
            meta: { title: '订单管理', menu: 'tenant_orders', permission: 'menu:tenant_orders' },
            children: [
              {
                path: ':id',
                name: 'tenant_termination_order',
                component: () => import('@/views/orders/tenant/TerminationlOrder'),
                meta: { title: '核算订单', menu: 'tenant_termination_order', permission: 'menu:tenant_orders' }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/tenant_service_order_deaths',
    component: BasicLayout,
    children: [
      {
        path: '/tenant_service_order_deaths',
        name: 'tenant_service_order_deaths',
        component: () => import('@/views/service_order_deaths/tenant/index.vue'),
        meta: { title: '逝者列表', menu: 'tenant_service_order_deaths', permission: 'menu:tenant_dead' }
      }
    ]
  },

  {
    path: '/tenant_mini_program_users',
    component: BasicLayout,
    children: [
      {
        path: '/tenant_mini_program_users',
        name: 'tenant_mini_program_users',
        component: () => import('@/views/mini_program_users/tenant/index.vue'),
        meta: { title: '小程序用户', menu: 'tenant_mini_program_users', permission: 'menu:tenant_mini_program' }
      }
    ]
  },

  {
    path: '/dead_data',
    component: CustomStyleLayout,
    meta: { menu: 'report' },
    children: [
      {
        path: '/dead_data',
        name: 'dead_data',
        component: () => import('@/views/dead_data/index'),
        meta: { title: '逝者数据', menu: 'dead_data', permission: 'menu:tenant_statistics' }
      }
    ]
  },
  {
    path: '/package_product_data',
    component: CustomStyleLayout,
    meta: { menu: 'report' },
    children: [
      {
        path: '/package_product_data',
        name: 'package_product_data',
        component: () => import('@/views/package_product_data/index'),
        meta: { title: '套餐产品数据', menu: 'package_product_data', permission: 'menu:tenant_statistics' }
      }
    ]
  },

  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'report' },
    children: [
      {
        path: '/',
        component: Empty,
        meta: { menu: 'fee_data' },
        children: [
          {
            path: '/fee_statistics',
            name: 'fee_statistics',
            component: () => import('@/views/fee_data/fee_statistics/index'),
            meta: { title: '订单费用统计', menu: 'fee_statistics', permission: 'menu:tenant_statistics' }
          },
          {
            path: '/order_fee_details',
            name: 'order_fee_details',
            component: () => import('@/views/fee_data/order_fee_details/index'),
            meta: { title: '订单费用明细', menu: 'order_fee_details', permission: 'menu:tenant_statistics' }
          },
          {
            path: '/sale_fee_details',
            name: 'sale_fee_details',
            component: () => import('@/views/fee_data/product_sale/index'),
            meta: { title: '产品销售统计', menu: 'sale_fee_details', permission: 'menu:tenant_statistics' }
          },
          {
            path: '/hall_fee_details',
            name: 'hall_fee_details',
            component: () => import('@/views/fee_data/hall_fee_details/index'),
            meta: { title: '厅房费用明细', menu: 'hall_fee_details', permission: 'menu:tenant_statistics' }
          },
          {
            path: '/wechat_flows',
            name: 'wechat_flows',
            component: () => import('@/views/fee_data/wechat_flows/index'),
            meta: { title: '微信小程序流水', menu: 'wechat_flows', permission: 'menu:tenant_statistics' }
          }
        ]
      }
    ]
  },

  {
    path: '/tenant_roles',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/tenant_roles',
        name: 'tenant_roles',
        component: () => import('@/views/roles/tenant/index'),
        meta: { title: '角色权限', menu: 'tenant_roles', permission: 'page:tenant_roles' }
      }
    ]
  },

  {
    path: '/tenant_account_management',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/tenant_account_management',
        name: 'tenant_account_management',
        component: () => import('@/views/users/tenant/index'),
        meta: { title: '账号管理', menu: 'tenant_account_management', permission: 'page:tenant_users' }
      }
    ]
  },

  {
    path: '/voucher',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/voucher',
        name: 'voucher',
        component: () => import('@/views/voucher/index'),
        meta: { title: '代金劵设置', menu: 'voucher', permission: 'menu:tenant_settings' }
      }
    ]
  },

  {
    path: '/banner',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/banner',
        name: 'banner',
        component: () => import('@/views/banner/index'),
        meta: { title: '小程序首页Banner', menu: 'banner', permission: 'menu:tenant_settings' }
      }
    ]
  },

  {
    path: '/obituary_background',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/obituary_background',
        name: 'obituary_background',
        component: () => import('@/views/obituary_background/index'),
        meta: { title: '讣告背景图片', menu: 'obituary_background', permission: 'menu:tenant_settings' }
      }
    ]
  },

  {
    path: '/vertical_screen_column',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/vertical_screen_column',
        name: 'vertical_screen_columns',
        component: () => import('@/views/vertical_screen_column/index'),
        meta: { title: '立式屏栏目管理', menu: 'vertical_screen_column', permission: 'menu:tenant_settings' }
      }
    ]
  },

  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/cars',
            name: 'cars',
            component: () => import('@/views/cars/index'),
            meta: { title: '车辆管理', menu: 'cars', permission: 'menu:tenant_settings' }
          },
          {
            path: '/funeral_service_points',
            name: 'funeral_service_points',
            component: () => import('@/views/funeral_service_points/index'),
            meta: { title: '殡仪服务点管理', menu: 'funeral_service_points', permission: 'menu:tenant_settings' }
          },
          {
            path: '/cemeteries',
            name: 'cemeteries',
            component: () => import('@/views/cemeteries/tenant/index'),
            meta: { title: '墓地管理', menu: 'cemeteries', permission: 'menu:tenant_settings' }
          },
          {
            path: '/frequently_asked_questions',
            name: 'frequently_asked_questions',
            component: () => import('@/views/frequently_asked_questions/index'),
            meta: { title: '常见问题', menu: 'frequently_asked_questions', permission: 'menu:tenant_settings' }
          },
          {
            path: '/process_notification',
            name: 'process_notification',
            component: () => import('@/views/process_notification/index'),
            meta: { title: '相关手续告知书', menu: 'process_notification', permission: 'menu:tenant_settings' }
          },
          {
            path: '/precaution',
            name: 'precaution',
            component: () => import('@/views/precaution/index'),
            meta: { title: '注意事项', menu: 'precaution', permission: 'menu:tenant_settings' }
          },
          {
            path: '/conventional_way',
            name: 'conventional_way',
            component: () => import('@/views/conventional_way/index'),
            meta: { title: '民俗详情', menu: 'conventional_way', permission: 'menu:tenant_settings' }
          }
        ]
      }
    ]
  },
  {
    path: '/funeral_homes',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/funeral_homes',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/funeral_homes',
            component: Empty,
            meta: { title: '殡仪馆管理', menu: 'funeral_homes', permission: 'menu:tenant_settings' },
            children: [
              {
                path: '/funeral_homes',
                name: 'funeral_homes',
                component: () => import('@/views/funeral_homes/tenant/index'),
                meta: { title: '', menu: 'funeral_homes', permission: 'menu:tenant_settings' }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/funeral_homes',
    component: CustomStyleLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/funeral_homes',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/funeral_homes',
            component: Empty,
            meta: { title: '殡仪馆管理', menu: 'funeral_homes', permission: 'menu:tenant_settings' },
            children: [
              {
                path: ':id',
                name: 'halls',
                component: () => import('@/views/halls/index'),
                meta: { title: '厅房管理', menu: 'halls', permission: 'menu:tenant_settings' }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/equipments',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/equipments',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/equipments',
            component: Empty,
            meta: { title: '设备管理', menu: 'equipments', permission: 'menu:tenant_settings' },
            children: [
              {
                path: '/equipments',
                name: 'equipments',
                component: () => import('@/views/equipments/index'),
                meta: { title: '', menu: 'equipments', permission: 'menu:tenant_settings' }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/equipments',
    component: CustomStyleLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/equipments',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/equipments',
            component: Empty,
            meta: { title: '设备管理', menu: 'equipments', permission: 'menu:tenant_settings' },
            children: [
              {
                path: ':id',
                name: 'cameras',
                component: () => import('@/views/cameras/index'),
                meta: { title: '摄像头管理', menu: 'cameras', permission: 'menu:tenant_settings' }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/tenant_products',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/tenant_products',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/tenant_products',
            component: Empty,
            meta: { title: '产品管理', menu: 'tenant_products', permission: 'menu:tenant_settings' },
            children: [
              {
                path: '/tenant_products',
                name: 'tenant_products',
                component: () => import('@/views/products/tenant/index'),
                meta: { title: '', menu: 'tenant_products', permission: 'menu:tenant_settings' }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/tenant_products',
    component: CustomStyleLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/tenant_products',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/tenant_products',
            component: Empty,
            meta: { title: '产品管理', menu: 'tenant_products', permission: 'menu:tenant_settings' },
            children: [
              {
                path: ':id',
                name: 'tenant_products_info',
                component: () => import('@/views/products/tenant/Show'),
                meta: { title: '产品详情', menu: 'tenant_products', permission: 'menu:tenant_settings' }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/tenant_package_management',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/tenant_package_management',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/tenant_package_management',
            component: Empty,
            meta: { title: '套餐管理', menu: 'tenant_package_management', permission: 'menu:tenant_settings' },
            children: [
              {
                path: '/tenant_package_management',
                component: Empty,
                meta: { title: '', menu: 'tenant_package_management', permission: 'menu:tenant_settings' },
                children: [
                  {
                    path: '/tenant_package_management',
                    name: 'tenant_package_management',
                    component: () => import('@/views/package_management/tenant/index'),
                    meta: { title: '', menu: 'tenant_package_management', permission: 'menu:tenant_settings' }
                  },
                  {
                    path: 'items',
                    name: 'tenant_package_items',
                    component: () => import('@/views/items/tenant/index'),
                    meta: { title: '套餐项目池管理', menu: 'tenant_package_management', permission: 'menu:tenant_settings' }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/tenant_package_management',
    component: CustomStyleLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/tenant_package_management',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/tenant_package_management',
            component: Empty,
            meta: { title: '套餐管理', menu: 'tenant_package_management', permission: 'menu:tenant_settings' },
            children: [
              {
                path: ':id',
                name: 'tenant_package_info',
                component: () => import('@/views/package_management/tenant/Show'),
                meta: { title: '套餐详情', menu: 'tenant_package_management', permission: 'menu:tenant_settings' }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/tenant_sms_records',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/tenant_sms_records',
        name: 'tenant_sms_records',
        component: () => import('@/views/sms_records/tenant/index'),
        meta: { title: '短信管理', menu: 'tenant_sms_records', permission: 'menu:tenant_settings' }
      }
    ]
  },
  {
    path: '/tenant_sms_records',
    component: CustomStyleLayout,
    meta: { title: '短信管理', menu: 'tenant_setting' },
    children: [
      {
        path: ':id',
        name: 'sms_info',
        component: () => import('@/views/sms_records/tenant/Show'),
        meta: { title: '短信详情', menu: 'tenant_sms_records', permission: 'menu:tenant_settings' }
      }
    ]
  },
  {
    path: '/info_modify_applies',
    component: BasicLayout,
    meta: { menu: 'business_review' },
    children: [
      {
        path: '/info_modify_applies',
        name: 'info_modify_applies',
        component: () => import('@/views/info_modify_applies/index'),
        meta: { title: '信息维护申请', menu: 'info_modify_applies', permission: 'menu:tenant_business_review' }
      }
    ]
  },
  {
    path: '/order_evaluates',
    component: BasicLayout,
    meta: { menu: 'business_review' },
    children: [
      {
        path: '/order_evaluates',
        name: 'order_evaluates',
        component: () => import('@/views/order_evaluates/index'),
        meta: { title: '评价管理', menu: 'order_evaluates', permission: 'menu:tenant_business_review' }
      }
    ]
  },
  {
    path: '/messages',
    component: BasicLayout,
    meta: { menu: 'business_review' },
    children: [
      {
        path: '/messages',
        name: 'messages',
        component: () => import('@/views/messages/index'),
        meta: { title: '留言管理', menu: 'messages', permission: 'menu:tenant_business_review' }
      }
    ]
  },
  {
    path: '/invoices',
    component: BasicLayout,
    meta: { menu: 'business_review' },
    children: [
      {
        path: '/invoices',
        name: 'invoices',
        component: () => import('@/views/invoices/index'),
        meta: { title: '发票申请', menu: 'invoices', permission: 'menu:tenant_business_review' }
      }
    ]
  },
  {
    path: '/',
    component: BasicLayout,
    meta: { menu: 'tenant_setting' },
    children: [
      {
        path: '/',
        component: Empty,
        meta: { menu: 'notification' },
        children: [
          {
            path: '/festival_reminds',
            name: 'festival_reminds',
            component: () => import('@/views/festival_reminds/tenant/index'),
            meta: { title: '节日提醒', menu: 'festival_reminds', permission: 'menu:tenant_settings' }
          },
          {
            path: '/death_day_reminds',
            name: 'death_day_reminds',
            component: () => import('@/views/death_day_reminds/tenant/index'),
            meta: { title: '忌辰提醒', menu: 'death_day_reminds', permission: 'menu:tenant_settings' }
          }
        ]
      }
    ]
  },

  {
    path: '/system_orders',
    component: BasicLayout,
    children: [
      {
        path: '/system_orders',
        name: 'system_orders',
        component: () => import('@/views/orders/system/index.vue'),
        meta: { title: '订单管理', menu: 'system_orders', permission: 'menu:system_orders' }
      }
    ]
  },

  {
    path: '/system_service_order_deaths',
    component: BasicLayout,
    children: [
      {
        path: '/system_service_order_deaths',
        name: 'system_service_order_deaths',
        component: () => import('@/views/service_order_deaths/system/index.vue'),
        meta: { title: '逝者列表', menu: 'system_service_order_deaths', permission: 'menu:system_dead' }
      }
    ]
  },

  {
    path: '/system_mini_program_users',
    component: BasicLayout,
    children: [
      {
        path: '/system_mini_program_users',
        name: 'system_mini_program_users',
        component: () => import('@/views/mini_program_users/system/index.vue'),
        meta: { title: '小程序用户', menu: 'system_mini_program_users', permission: 'menu:system_mini_program' }
      }
    ]
  },
  {
    path: '/tenants',
    component: BasicLayout,
    children: [
      {
        path: '/tenants',
        name: 'tenants',
        component: () => import('@/views/tenants/admin/index.vue'),
        meta: { title: '租户管理', menu: 'tenants', permission: 'menu:system_tenant' }
      }
    ]
  },
  {
    path: '/tenants',
    component: CustomStyleLayout,
    meta: { title: '租户管理', menu: 'tenants' },
    children: [
      {
        path: ':id',
        component: () => import('@/views/tenants/ShowTab.vue'),
        meta: { title: '', menu: 'tenants', permission: 'menu:system_tenant' },
        children: [
          {
            path: 'funeral_homes',
            name: 'tenant_funeral_homes',
            component: () => import('@/views/tenants/funeral_homes/index'),
            meta: { title: '租户详情', menu: 'tenant_funeral_homes', permission: 'menu:system_tenant' }
          },
          {
            path: 'halls',
            name: 'tenant_halls',
            component: () => import('@/views/tenants/halls/index'),
            meta: { title: '租户详情', menu: 'tenant_halls', permission: 'menu:system_tenant' }
          },
          {
            path: 'package_managements',
            name: 'tenant_package_managements',
            component: () => import('@/views/tenants/package_managements/index'),
            meta: { title: '租户详情', menu: 'tenant_package_managements', permission: 'menu:system_tenant' }
          },
          {
            path: 'products',
            name: 'tenant_admin_products',
            component: () => import('@/views/tenants/products/index'),
            meta: { title: '租户详情', menu: 'tenant_products', permission: 'menu:system_tenant' }
          },
          {
            path: 'users',
            name: 'tenant_users',
            component: () => import('@/views/tenants/users/index'),
            meta: { title: '租户详情', menu: 'tenant_users', permission: 'menu:system_tenant' }
          }
        ]
      }
    ]
  },

  {
    path: '/system_roles',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/system_roles',
        name: 'system_roles',
        component: () => import('@/views/roles/system/index'),
        meta: { title: '角色权限', menu: 'system_roles', permission: 'menu:system_settings' }
      }
    ]
  },

  {
    path: '/system_account_management',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/system_account_management',
        name: 'system_account_management',
        component: () => import('@/views/users/system/index'),
        meta: { title: '账号管理', menu: 'system_account_management', permission: 'menu:system_settings' }
      }
    ]
  },

  {
    path: '/system_items',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/system_items',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/system_items',
            component: Empty,
            meta: { title: '套餐项目池管理', menu: 'system_items', permission: 'menu:system_settings' },
            children: [
              {
                path: '/system_items',
                name: 'system_items',
                component: () => import('@/views/items/index'),
                meta: { title: '', menu: 'system_items', permission: 'menu:system_settings' }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/system_products',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/system_products',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/system_products',
            component: Empty,
            meta: { title: '产品管理', menu: 'system_products', permission: 'menu:system_settings' },
            children: [
              {
                path: '/system_products',
                name: 'system_products',
                component: () => import('@/views/products/system/index'),
                meta: { title: '', menu: 'system_products', permission: 'menu:system_settings' }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/system_products',
    component: CustomStyleLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/system_products',
        component: Empty,
        meta: { menu: 'information_maintenance' },
        children: [
          {
            path: '/system_products',
            component: Empty,
            meta: { title: '产品管理', menu: 'system_products', permission: 'menu:system_settings' },
            children: [
              {
                path: ':id',
                name: 'system_products_info',
                component: () => import('@/views/products/system/Show'),
                meta: { title: '产品详情', menu: 'system_products', permission: 'menu:system_settings' }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/system_sms_records',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/system_sms_records',
        name: 'system_sms_records',
        component: () => import('@/views/sms_records/system/index'),
        meta: { title: '短信记录', menu: 'system_sms_records', permission: 'menu:system_settings' }
      }
    ]
  },
  {
    path: '/system_sms_records',
    component: CustomStyleLayout,
    meta: { title: '短信记录', menu: 'system_setting' },
    children: [
      {
        path: ':id',
        name: 'system_sms_info',
        component: () => import('@/views/sms_records/system/Show'),
        meta: { title: '短信详情', menu: 'system_sms_records', permission: 'menu:system_settings' }
      }
    ]
  },
  {
    path: '/camera_open_times',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/camera_open_times',
        name: 'camera_open_times',
        component: () => import('@/views/camera_open_times/index'),
        meta: { title: '设备开放时间', menu: 'camera_open_times', permission: 'menu:system_settings' }
      }
    ]
  },
  {
    path: '/app_privacy_agreement',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/app_privacy_agreement',
        component: Empty,
        meta: { menu: 'agreement_management' },
        children: [
          {
            path: '/app_privacy_agreement',
            component: Empty,
            meta: { title: 'APP用户隐私协议管理', menu: 'app_privacy_agreement', permission: 'menu:system_settings' },
            children: [
              {
                path: '/app_privacy_agreement',
                name: 'app_privacy_agreement',
                component: () => import('@/views/agreements/app_privacy/index'),
                meta: { title: '', menu: 'app_privacy_agreement', permission: 'menu:system_settings' }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/app_use_agreement',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/app_use_agreement',
        component: Empty,
        meta: { menu: 'agreement_management' },
        children: [
          {
            path: '/app_use_agreement',
            component: Empty,
            meta: { title: 'APP用户使用协议管理', menu: 'app_use_agreement', permission: 'menu:system_settings' },
            children: [
              {
                path: '/app_use_agreement',
                name: 'app_use_agreement',
                component: () => import('@/views/agreements/app_use/index'),
                meta: { title: '', menu: 'app_use_agreement', permission: 'menu:system_settings' }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/app_upgrade',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/app_upgrade',
        component: Empty,
        meta: { menu: 'agreement_management' },
        children: [
          {
            path: '/app_upgrade',
            component: Empty,
            meta: { title: 'APP应用更新', menu: 'app_upgrade', permission: 'menu:system_settings' },
            children: [
              {
                path: '/app_upgrade',
                name: 'app_upgrade',
                component: () => import('@/views/agreements/app_upgrade/index'),
                meta: { title: '', menu: 'app_upgrade', permission: 'menu:system_settings' }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/mini_privacy_agreement',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/mini_privacy_agreement',
        component: Empty,
        meta: { menu: 'agreement_management' },
        children: [
          {
            path: '/mini_privacy_agreement',
            component: Empty,
            meta: { title: '小程序用户隐私协议管理', menu: 'mini_privacy_agreement', permission: 'menu:system_settings' },
            children: [
              {
                path: '/mini_privacy_agreement',
                name: 'mini_privacy_agreement',
                component: () => import('@/views/agreements/mini_privacy/index'),
                meta: { title: '', menu: 'mini_privacy_agreement', permission: 'menu:system_settings' }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/mini_use_agreement',
    component: BasicLayout,
    meta: { menu: 'system_setting' },
    children: [
      {
        path: '/mini_use_agreement',
        component: Empty,
        meta: { menu: 'agreement_management' },
        children: [
          {
            path: '/mini_use_agreement',
            component: Empty,
            meta: { title: '小程序用户使用协议管理', menu: 'mini_use_agreement', permission: 'menu:system_settings' },
            children: [
              {
                path: '/mini_use_agreement',
                name: 'mini_use_agreement',
                component: () => import('@/views/agreements/mini_use/index'),
                meta: { title: '', menu: 'mini_use_agreement', permission: 'menu:system_settings' }
              }
            ]
          }
        ]
      }
    ]
  }
]
