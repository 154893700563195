import store from '@/store'

// 设置浏览器tab页标题
export const setPageTitle = function(route) {
  // 查找所有包含 meta.title 的嵌套路由
  const routes = route.matched.filter(item => item.meta && item.meta.title && !(item.meta.title.startsWith(':')))
  let title = window.customConfig.title
  if (routes.length > 0) {
    // 设置最后一个出现的 title (即子路由设置的 title 会覆盖父路由中的 title)
    const pageTitle = routes[routes.length - 1].meta.title
    title = `${pageTitle} - ${window.customConfig.title}`
  }

  if (store.getters.unreadNotificationCount > 0) {
    title = `(${store.getters.unreadNotificationCount} 条新消息) ${title}`
  }

  document.title = title
}

// 设置菜单选中(导航菜单高亮)
// export const setActiveMenu = function(route) {
//   // 查找所有包含 meta.menu 的嵌套路由
//   const routes = route.matched.filter(item => item.meta && item.meta.menu)
//   if (routes.length > 0) {
//     // 设置最后一个出现的 menu (即子路由设置的 menu 会覆盖父路由中的 menu)
//     const menu = routes[routes.length - 1].meta.menu
//     store.dispatch('SetCurrentMenu', [menu]).then(() => {})
//   } else {
//     store.dispatch('SetCurrentMenu', []).then(() => {})
//   }
// }
export const setActiveMenu = function(route) {
  // 查找所有包含 meta.menu 的嵌套路由
  const routes = route.matched.filter(item => item.meta && item.meta.menu)
  if (routes.length > 0) {
    // 设置所有出现的 menu
    const menus = routes.map(item => item.meta.menu)
    store.dispatch('SetCurrentMenu', menus).then(() => {})
  } else {
    store.dispatch('SetCurrentMenu', []).then(() => {})
  }
}

// 设置面包屑
export const setBreadCrumbs = function(route) {
  const breadList = route.matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
  store.dispatch('SetDynamicBreadCrumbs', breadList).then(() => {})
}

// 设置网站 tab 图标
export const setFavicon = function(faviconHref) {
  document.getElementById('favicon').href = faviconHref
}
