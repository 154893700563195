<template>
  <a-breadcrumb :style="{ margin: '16px 0' }">
    <a-breadcrumb-item
      v-for="(routeRecord, index) in breadList"
      :key="routeRecord.path"
    >
      <a
        v-if="isRouteCanClick(routeRecord) && index !== breadList.length - 1"
        @click.prevent="handleLink(routeRecord)"
      >{{ formatTitle(routeRecord) }}</a>
      <span
        v-else
      >{{ formatTitle(routeRecord) }}</span>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
import { compile } from 'path-to-regexp'

export default {
  name: 'BreadCrumb',
  computed: {
    breadList() {
      return this.$store.getters.dynamicBreadCrumbs.routes
    },

    breadPlaceholder() {
      return this.$store.getters.dynamicBreadCrumbs.placeholder
    }
  },
  methods: {
    formatTitle(routeRecord) {
      if (routeRecord.meta.title.startsWith(':')) {
        // 有占位符, 需要替换值
        if (this.breadPlaceholder[routeRecord.meta.title]) {
          return this.breadPlaceholder[routeRecord.meta.title]
        } else {
          return '...'
        }
      } else {
        return routeRecord.meta.title
      }
    },

    isRouteCanClick(routeRecord) {
      if (routeRecord.redirect === 'noRedirect') {
        return false
      }

      return routeRecord.name !== this.$route.name && routeRecord.path !== this.$route.path
    },

    pathCompile(path) {
      // 解决路由中参数问题 path: ':id/details'
      const { params } = this.$route
      const toPath = compile(path)
      return toPath(params)
    },

    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
  }
}
</script>
