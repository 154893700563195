import Home from '@/views/home'
import CustomStyleLayout from '@/layouts/CustomStyleLayout'

// 基础路由(不受权限控制的，所有角色都有的路由)
export const constantRouter = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: { title: '欢迎登录' }
  },
  {
    path: '/app_privacy',
    name: 'app_privacy',
    component: () => import('@/views/agreements/app_privacy/AppPrivate.vue')
  },
  {
    path: '/app_use',
    name: 'app_use',
    component: () => import('@/views/agreements/app_use/AppUse.vue')
  },
  {
    path: '/',
    component: CustomStyleLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: { title: '首页', menu: 'home' }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/exception/404')
  },
  {
    path: '/wechat',
    name: 'wechat',
    component: () => import('@/views/wechat/index.vue'),
    meta: { title: '微信授权' }
  },{
    path: '/hik_video',
    name: 'hik_video',
    component: () => import('@/views/hikvision/index.vue'),
    meta: { title: '海康视频' }
  },
]
