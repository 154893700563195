<template>
  <a-layout class="layout">
    <layout-header />

    <a-layout
      class="section"
      :has-sider="true"
    >
      <layout-sider />
      <layout-content />
    </a-layout>
  </a-layout>
</template>

<script>
import LayoutHeader from '@/components/LayoutHeader/index'
import LayoutSider from '@/components/LayoutSider/index'
import LayoutContent from '@/components/LayoutContent/CustomStyle'

export default {
  name: 'BasicLayout',
  components: {
    LayoutHeader,
    LayoutSider,
    LayoutContent
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/variables";

// 内部 layout 相关
.layout {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  min-width: 1366px;
  height: 100vh;

  .section {
    padding: @section-padding;
  }
}
</style>
