import { login, findUserInfo, logout } from '@/api/login'
import { clearStorage, getToken, setToken } from '@/utils/token'
import Vue from 'vue'

const user = {
  state: {
    id: 0,
    empName: '',
    role: '',
    permissions: [],
    appointmentAuthority: false,
    videoAuthority: false,
  },

  mutations: {
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USER_INFO: (state, {
      id,
      empName,
      role,
      appointmentAuthority,
      videoAuthority,
    }) => {
      state.id = id
      state.empName = empName
      state.role = role
      state.appointmentAuthority = appointmentAuthority
      state.videoAuthority = videoAuthority
    },
    CLEAR_USER_INFO: (state) => {
      state.id = 0
      state.empName = ''
      state.role = ''
      state.appointmentAuthority = false
      state.videoAuthority = false
    }
  },

  actions: {
    // 清除登录信息
    ClearLoginInfo({ commit }) {
      return new Promise(resolve => {
        commit('SET_PERMISSIONS', [])
        commit('CLEAR_USER_INFO', [])
        commit('CLEAR_NOTIFICATION_DATA')
        clearStorage()
        resolve()
      })
    },

    // 登录
    Login({ dispatch, commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          if (res.code === 0) {
            dispatch('ClearLoginInfo')
            setToken(res.data.token)
            Vue.prototype.$socket.connect()
            dispatch('PullNotifications')
            dispatch('SetIsNeedCheckAutoAlertNotification', true)
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    FindUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        findUserInfo().then(res => {
          if (res.code === 0) {
            const { data } = res
            commit('SET_PERMISSIONS', data.permissions)
            commit('SET_USER_INFO', {
              id: data.id,
              empName: data.emp_name,
              role: data.role,
              appointmentAuthority: data.appointment_authority,
              videoAuthority: data.video_authority
            })
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout({ dispatch }) {
      return new Promise((resolve) => {
        logout(getToken()).then((res) => {
          if (res.code === 0) {
            dispatch('ClearLoginInfo')
            Vue.prototype.$socket.close()
            resolve(res)
          }
        }).catch((err) => {
          resolve(err)
        }).finally(() => {
        })
      })
    }
  }
}

export default user
