import request from '@/utils/request'

// 切换用户登录
export function switchUser(data) {
  return request({
    url: `/switch_user`,
    method: 'post',
    data
  })
}

// 返回初始账号登录
export function switchBack(data) {
  return request({
    url: `/switch_back`,
    method: 'post',
    data
  })
}
