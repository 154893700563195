const getters = {
  unreadNotificationCount: state => state.notification.unreadCount,
  isNeedCheckAutoAlertNotification: state => state.notification.isNeedCheckAutoAlertNotification,
  autoAlertNotificationId: state => state.notification.autoAlertNotificationId,
  userId: state => state.user.id,
  userEmpName: state => state.user.empName,
  userRole: state => state.user.role,
  permissions: state => state.user.permissions,
  dynamicBreadCrumbs: state => state.app.dynamicBreadCrumbs,
  currentMenus: state => state.app.currentMenus,
  appointmentAuthority: state => state.user.appointmentAuthority,
  videoAuthority: state => state.user.videoAuthority,
}

export default getters
