import Vue from 'vue'
import VueRouter from 'vue-router'
import { asyncRouter } from '@/router/async_router'
import { constantRouter } from '@/router/constant_router'

Vue.use(VueRouter)

const routes = [
  ...constantRouter,
  ...asyncRouter,
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
