<template>
  <div class="home">
    <system-home v-if="isShowMenu('menu:system_home')" />
    <tenant-home v-else />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import SystemHome from '@/views/home/system/index'
import TenantHome from '@/views/home/tenant/index'
export default {
  name: 'Home',
  components: {
    SystemHome,
    TenantHome
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    }
  }
}
</script>

<style lang="less" scoped>
.box-height {
  height: 300px;
}
</style>
