import request from '@/utils/request'

// 查询系统版本
export function findAppVersion() {
  return request({
    url: `/version.json?t=${new Date().getTime()}`,
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_URL
  })
}
