<template>
  <div class="home">
    <a-row :gutter="[16,16]">
      <a-col span="8">
        <div class="custom-card-box">
          <div class="top-box-height">
            <order-data-statistics class="custom-margin-bottom-16" />
            <vue-lazy-component>
              <responsible-person-order class="custom-margin-bottom-16" />
              <order-type-statistics />
            </vue-lazy-component>
          </div>
        </div>
      </a-col>

      <a-col span="8">
        <div class="custom-card-box">
          <div class="top-box-height">
            <task-statistics class="custom-margin-bottom-16" />
            <vue-lazy-component>
              <responsible-person-task />
            </vue-lazy-component>
          </div>
        </div>
      </a-col>

      <a-col span="8">
        <div class="custom-card-box custom-margin-bottom-16">
          <div class="top-box-height">
            <hall-data-statistics />
          </div>
        </div>

        <vue-lazy-component>
          <other-data-statistics class="custom-card-box" />
        </vue-lazy-component>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    // 租户数据统计
    HallDataStatistics: () => import('@/views/home/tenant/modules/hall_data_statistics.vue'),
    OrderDataStatistics: () => import('@/views/home/tenant/modules/order_data_statistics.vue'),
    OrderTypeStatistics: () => import('@/views/home/tenant/modules/order_type_statistics.vue'),
    OtherDataStatistics: () => import('@/views/home/tenant/modules/other_data_statistics.vue'),
    ResponsiblePersonTask: () => import('@/views/home/tenant/modules/responsible_person_task.vue'),
    TaskStatistics: () => import('@/views/home/tenant/modules/task_statistics.vue'),
    ResponsiblePersonOrder: () => import('@/views/home/tenant/modules/responsible_person_order.vue')
  }
}
</script>

<style lang="less" scoped>
.box-height {
  height: 300px;
}
</style>
