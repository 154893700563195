import request from '@/utils/request'
import { SKIP_EXTEND_AUTH_EXPIRE_TIME_HEADER } from '@/utils/token'

// 通知消息列表
export function findNotifications(params) {
  return request({
    url: `/notifications`,
    method: 'get',
    params: params
  })
}

// 通知消息详情
export function findNotification(id) {
  return request({
    url: `/notifications/${id}`,
    method: 'get'
  })
}

// 需要自动弹出的通知
export function findAlertNotification() {
  return request({
    url: `/notifications/alert`,
    method: 'get'
  })
}

// 拉取通知
export function pullNotifications() {
  const headers = {}
  headers[SKIP_EXTEND_AUTH_EXPIRE_TIME_HEADER] = '1'

  return request({
    url: `/notifications/pull`,
    method: 'post',
    headers: headers
  })
}

// 全部标记已读
export function updateNotificationsAllRead() {
  return request({
    url: `/notifications/all_read`,
    method: 'post'
  })
}

// 删除通知
export function deleteNotifications(data) {
  return request({
    url: `/notifications/batch`,
    method: 'delete',
    data
  })
}

// 删除全部通知
export function deleteAllNotifications() {
  return request({
    url: `/notifications/all`,
    method: 'delete'
  })
}
