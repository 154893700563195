<template>
  <a-layout-sider width="200" class="sider">
    <system-layoutSider v-if="['super_admin', 'system_admin'].includes(roleSlug)" />
    <tenant-layoutSider v-else />
  </a-layout-sider>
</template>

<script>
import SystemLayoutSider from './system'
import TenantLayoutSider from './tenant'
export default {
  components: {
    SystemLayoutSider,
    TenantLayoutSider
  },
  computed: {
    roleSlug() {
      return this.$store.getters.userRole
    }
  }
}
</script>

<style lang="less" scoped>
.sider {
  overflow: auto;
  margin-right: 20px;

  // 滚动条
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
