<template>
  <a-menu
    mode="inline"
    theme="light"
    :open-keys="openedMenuKeys"
    v-model="currentMenus"
    @openChange="onOpenChange"
    class="menus-box"
  >
    <a-menu-item key="home" v-if="isShowMenu('menu:system_home')">
      <router-link :to="{ name: 'home' }">
        <a-icon type="home" />
        <span>首页</span>
      </router-link>
    </a-menu-item>

    <a-menu-item key="system_orders" v-if="isShowMenu('menu:system_orders')">
      <router-link :to="{ name: 'system_orders' } ">
        <a-icon type="profile" />
        <span>订单管理</span>
      </router-link>
    </a-menu-item>

    <a-menu-item key="system_service_order_deaths" v-if="isShowMenu('menu:system_dead')">
      <router-link :to="{ name: 'system_service_order_deaths' } ">
        <a-icon type="user-delete" />
        <span>逝者列表</span>
      </router-link>
    </a-menu-item>

    <a-menu-item key="system_mini_program_users" v-if="isShowMenu('menu:system_mini_program')">
      <router-link :to="{ name: 'system_mini_program_users' } ">
        <a-icon type="link" />
        <span>小程序用户</span>
      </router-link>
    </a-menu-item>

    <a-menu-item key="tenants" v-if="isShowMenu('menu:system_tenant')">
      <router-link :to="{ name: 'tenants' } ">
        <a-icon type="team" />
        <span>租户管理</span>
      </router-link>
    </a-menu-item>

    <a-sub-menu key="system_setting" v-if="isShowMenu('menu:system_settings')">
      <span slot="title">
        <a-icon type="setting" />
        <span>系统设置</span>
      </span>

      <a-menu-item key="system_roles">
        <router-link :to="{ name: 'system_roles' }">
          <span>角色权限</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="system_account_management">
        <router-link :to="{ name: 'system_account_management' }">
          <span>账号管理</span>
        </router-link>
      </a-menu-item>

      <a-sub-menu key="information_maintenance" title="信息维护">
        <a-menu-item key="system_items">
          <router-link :to="{ name: 'system_items' }" />
          <span>套餐项目池管理</span>
        </a-menu-item>

        <a-menu-item key="system_products">
          <router-link :to="{ name: 'system_products' }" />
          <span>产品管理</span>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item key="system_sms_records">
        <router-link :to="{ name: 'system_sms_records' }">
          <span>短信记录</span>
        </router-link>
      </a-menu-item>

      <a-sub-menu key="agreement_management" title="协议与应用管理">
        <a-menu-item key="app_privacy_agreement">
          <router-link :to="{ name: 'app_privacy_agreement' }" />
          <span>APP隐私协议</span>
        </a-menu-item>

        <a-menu-item key="app_use_agreement">
          <router-link :to="{ name: 'app_use_agreement' }" />
          <span>APP使用协议</span>
        </a-menu-item>

        <a-menu-item key="app_upgrade">
          <router-link :to="{ name: 'app_upgrade' }" />
          <span>APP应用更新</span>
        </a-menu-item>

        <a-menu-item key="mini_privacy_agreement">
          <router-link :to="{ name: 'mini_privacy_agreement' }" />
          <span>小程序隐私协议</span>
        </a-menu-item>

        <a-menu-item key="mini_use_agreement">
          <router-link :to="{ name: 'mini_use_agreement' }" />
          <span>小程序使用协议</span>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item key="camera_open_times">
        <router-link :to="{ name: 'camera_open_times' }">
          <span>设备开放时间</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>

<script>
import { hasPermission } from '@/utils/permission'
export default {
  name: 'SystemLayoutSider',
  data() {
    return {
      rootSubmenuKeys: [
        'home',
        'report',
        'system_setting'
      ],
      openedMenuKeys: this.$store.getters.currentMenus
    }
  },
  computed: {
    // 当前选中的菜单
    currentMenus: {
      get() {
        return this.$store.getters.currentMenus
      },
      set(val) {
        // setActiveMenu 中设置
        // 这里给一个空的 setter, 解决在新标签打开菜单报错问题
      }
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.openedMenuKeys = this.currentMenus
      }
    }
  },
  methods: {
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openedMenuKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openedMenuKeys = openKeys
      } else {
        this.openedMenuKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    }
  }
}
</script>

<style lang="less" scoped>
.menus-box {
  border: none;
}
</style>
