<template>
  <div class="home">
    <a-row :gutter="[16,16]">
      <a-col span="8">
        <div class="custom-card-box">
          <div class="top-box-height">
            <system-order-data-statistics />
          </div>
        </div>
      </a-col>

      <a-col span="8">
        <div class="custom-card-box">
          <div class="top-box-height">
            <system-task-statistics />
          </div>
        </div>
      </a-col>

      <a-col span="8">
        <div class="custom-card-box">
          <div class="top-box-height">
            <system-hall-data-statistics />
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[16,16]">
      <a-col span="8">
        <div class="custom-card-box">
          <div class="bottom-box-height">
            <system-order-type-statistics />
          </div>
        </div>
      </a-col>

      <a-col span="8">
        <div class="custom-card-box">
          <div class="bottom-box-height">
            <tenant-number-statistics />
          </div>
        </div>
      </a-col>

      <a-col span="8">
        <div class="custom-card-box">
          <div class="bottom-box-height">
            <system-other-data-statistics />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    // 集团数据统计
    SystemHallDataStatistics: () => import('@/views/home/system/modules/hall_data_statistics.vue'),
    SystemOrderDataStatistics: () => import('@/views/home/system/modules/order_data_statistics.vue'),
    SystemOrderTypeStatistics: () => import('@/views/home/system/modules/order_type_statistics.vue'),
    SystemOtherDataStatistics: () => import('@/views/home/system/modules/other_data_statistics.vue'),
    SystemTaskStatistics: () => import('@/views/home/system/modules/task_statistics.vue'),
    TenantNumberStatistics: () => import('@/views/home/system/modules/tenant_number_statistics.vue')
  }
}
</script>

<style lang="less" scoped>
.top-box-height {
  height: 330px;
}
.bottom-box-height {
  height: 500px;
}
</style>
