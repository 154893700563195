import store from '@/store'

// 是否有权限
export function hasPermission(permission) {
  if (typeof permission === 'undefined') {
    return true
  }
  // TODO use Hash
  return store.getters.permissions.includes(permission)
}
