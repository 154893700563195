import request from '@/utils/request'
import { SKIP_EXTEND_AUTH_EXPIRE_TIME_HEADER } from '@/utils/token'

// 通知消息列表
export function findNewOrderNotices(params) {
  return request({
    url: `/notices/new_order`,
    method: 'get',
    params: params
  })
}

// 标记全部已读
export function readAllNewOrderNotices() {
  return request({
    url: `/notices/new_order/read_all`,
    method: 'post'
  })
}

// 标记已读
export function readNotice(id) {
  return request({
    url: `/notices/${id}/read`,
    method: 'post'
  })
}

// 拉取通知
export function pullNotifications() {
  const headers = {}
  headers[SKIP_EXTEND_AUTH_EXPIRE_TIME_HEADER] = '1'

  return request({
    url: `/notices/pull`,
    method: 'post',
    headers: headers
  })
}
